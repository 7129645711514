import CategoryVideo from "./CategoryVideo";

export default {
  props: {
    category: null,
  },
  components: {
    "category-video": CategoryVideo,
  },
  template: `
    <header v-if="category && (category.header || category.video_url)" class="content-wrapper white-background">
      <div class="content-container content-block">
        <div class="content-inner">
          <div class="content-main">
            <category-video
                v-bind:videoUrl="category.video_url"
            ></category-video>
            <h3 v-if="category.header">{{ category.header }}</h3>
            <p v-if="category.description">{{ category.description }}</p>
          </div>
        </div>
      </div>
    </header>
  `,
};
