// This object is the top level Vue instance
// It grabs the data on the page and also filters it based on categories and
// a string query. After that, it passes all the filtered item data to a
// List component, which will have its own listing component as well.

// Import modules for JS use
import includes from "lodash/includes";
import every from "lodash/every";
import throttle from "lodash/throttle";
import { addClass, removeClass } from "./dom-helpers";

// Import Vue components that can be used inside the instance
import ProjectList from "./vue/ProjectList";
import TeamList from "./vue/TeamList";

export default {
  el: "#filterable-list",
  delimiters: ["<~", "~>"],
  data: {
    currentCategory: null,
    query: "",
    moreOpen: true,
    searchOpen: false,
    mobileFiltersOpen: false,
    customSearchActive: false,
    currentSearch: null,
    moreSelected: false,
    queryError: false,
    isMobile: false,
    categories: window.bootstrapCategories ? window.bootstrapCategories : null,
    items: window.bootstrapItems ? window.bootstrapItems : null,
    customSearches: window.customSearches ? window.customSearches : null,
  },
  computed: {
    filteredItems: function () {
      // This computed property gets re-computed
      // intelligently every time either this.currentCategory or
      // this.query changes. It then updates the child components
      // with new props.

      // Clone the item reference in a local scope so that
      // they can be used inside functional filter/includes calls.
      let items = this.items;

      if (this.currentCategory) {
        // Filter items by category first, if one is set
        const catId = this.currentCategory;
        items = items.filter(function (project) {
          return includes(project.categoryIds, catId);
        });
      }

      if (this.query && this.query.length > 0) {
        // Remove any URL created query errors (see method: setQueryByHash)
        this.queryError = false;

        // Take the filtered item list and and further filter it by
        // a query string, if there is one.
        const queryKeys = this.query.toLowerCase().split(" ");
        items = items.filter(function (project) {
          const projectKeywords = project.keywordsable;
          return every(queryKeys, function (key) {
            return projectKeywords.indexOf(key) > -1;
          });
        });
      }

      if (!this.query && !this.currentCategory) {
        // Remove "board" persons from items when no query or category is set
        // Ensures "board" persons do not appear in Team List "all" section
        items = items.filter(function (person) {
          return !person.keywordsable.includes("ext-board");
        });
      }

      return items;
    },
    categorySlug: function () {
      // When this.currentCategory changes, this is intelligently run to regenerate the
      // current category slug, which can be passed down to child components.
      return this.currentCategory
        ? this.getCategorySlugById(this.currentCategory)
        : null;
    },
    categoryName: function () {
      const id = this.currentCategory;

      let name;

      if (this.customSearchActive) {
        if (this.query === "leadership") name = "leadership";
        if (this.query === "ext-board") name = "ext-board";
      }

      this.categories.forEach(function (category) {
        if (id === category.id) {
          name = category.name;
        }
      });

      return name ?? "all";
    },
  },
  methods: {
    resetAll: function () {
      this.setCategory(null);
      this.query = "";
      this.customSearchActive = false;
    },
    setCategory: function (id, moreFlag) {
      // Set the category based on the ID, and update the history state too.
      this.currentSearch = null;
      this.currentCategory = id;
      const categorySlug = this.getCategorySlugById(id);
      // We might not have the slug yet (from this.categorySlug) so set it locally and push
      // it to the browser state if the category has one
      if (categorySlug) {
        history.pushState(null, document.title, "#" + categorySlug);
      } else {
        // Category has no slug, or was null
        history.pushState(null, document.title, window.location.pathname);
      }

      if (this.customSearchActive) {
        this.query = "";
        this.customSearchActive = false;
      }

      if (moreFlag) {
        this.moreSelected = true;
      } else {
        this.moreSelected = false;
      }

      if (this.isMobile) {
        this.mobileFiltersOpen = false;
        const body = document.querySelector("body");
        removeClass(body, "mobile-filters-active");
      }
    },
    getCategorySlugById: function (id) {
      // Look up a category id by slug. Used to set the categorySlug instance data
      // from the currentCategory
      let slug = null;
      this.categories.forEach(function (category) {
        if (id === category.id) {
          slug = category.slug;
        }
      });

      return slug;
    },
    getCategoryIdBySlug: function (slug) {
      // Look up a category slug by ID. Used to assing current category from a URL hash
      let id = null;
      this.categories.forEach(function (category) {
        if (slug === category.slug) {
          id = category.id;
        }
      });

      return id;
    },
    setQueryByHash: function (hash) {
      // Set a filter query (category OR search) based on a url hash.
      // IE:
      // url.com#category would auto-apply a category
      // ur.com#search-string would not find a category, and be used as a search query

      // Try to set the filter query by category
      const hashCategoryId = this.getCategoryIdBySlug(hash.toLowerCase());
      if (hashCategoryId) {
        this.setCategory(hashCategoryId);
        // If this category is a "more category" this.moreOpen should be
        // set to true here.
      } else {
        // Otherwise, check if it makes a successful hash
        const searchedItems = this.items.filter(function (project) {
          return project.keywordsable.indexOf(hash.toLowerCase()) > -1;
        });

        if (searchedItems.length > 0) {
          // If the hash is guaranteed to find something, set is as the query
          this.query = hash;
          this.searchOpen = true;
          [...this.customSearches].forEach((customSearch) => {
            if (hash === customSearch.id) {
              this.searchOpen = false;
              this.customSearchActive = true;
            }
          });
        } else {
          // Otherwise show an error that the hash did not yield results on the front-end
          this.queryError = true;
        }
      }
    },
    setCustomSearch: function (id) {
      this.resetAll();
      this.setQueryByHash(id);
      this.searchOpen = false;
      this.customSearchActive = true;
      this.currentSearch = id;

      // We might not have the slug yet (from this.categorySlug) so set it locally and push
      // it to the browser state if the category has one
      if (id) {
        history.pushState(null, document.title, "#" + id);
      } else {
        // Category has no slug, or was null
        history.pushState(null, document.title, window.location.pathname);
      }
    },
    toggleMobileFilters: function () {
      this.mobileFiltersOpen = !this.mobileFiltersOpen;

      const body = document.querySelector("body");

      if (this.mobileFiltersOpen) {
        addClass(body, "mobile-filters-active");
      } else {
        removeClass(body, "mobile-filters-active");
      }
    },
    toggleSearch: function () {
      this.searchOpen = !this.searchOpen;
    },
    closeSearch: function () {
      this.searchOpen = false;
      this.query = "";
    },
    sizeCheck: function () {
      if (window.innerWidth >= 420) {
        this.isMobile = false;
        this.mobileFiltersOpen = false;

        removeClass(document.querySelector("body"), "mobile-filters-active");
      } else {
        this.isMobile = true;
        this.moreOpen = false;
        this.searchOpen = false;
      }
    },
  },
  mounted: function () {
    // When the Vue app is mounted, the default category can be set from a hash
    if (location.hash.substring(1)) {
      this.setQueryByHash(location.hash.substring(1));
    }

    // Also set the category if the hash is changed by the browser or something
    window.addEventListener("hashchange", () => {
      this.setQueryByHash(location.hash.substring(1));
    });

    this.sizeCheck();

    // Keep track of whether we're showing the mobile or desktop filters nav
    window.addEventListener(
      "resize",
      throttle(() => {
        this.sizeCheck();
      }, 300)
    );
  },
  components: {
    "project-list": ProjectList,
    "team-list": TeamList,
  },
};
