import throttle from "lodash/throttle";
import { hasClass } from "./dom-helpers";

class LinkTargets {
  constructor() {
    // Find all anchor link targets
    const targets = document.querySelectorAll("a[name]");

    [...targets].forEach((target) => {
      if (hasClass(target, "anchor-target")) {
        const anchor = new TargetLink(target);
      }
    });
  }
}

class TargetLink {
  constructor(node) {
    this.setStyles(node, this.headerHeightCalculator());

    window.addEventListener(
      "resize",
      throttle(() => {
        this.setStyles(node, this.headerHeightCalculator());
      }, 2000)
    );
  }

  setStyles(node, offset) {
    // node.style.height = offset + 'px';
    // node.style.marginTop = '-' + offset + 'px';
    node.style.top = "-" + offset + "px";
  }

  headerHeightCalculator() {
    let headerHeight = 79;
    const subNav = document.querySelector("[data-sticky-header=sub-nav]");
    const windowWidth = window.innerWidth;

    if (windowWidth <= 420) {
      headerHeight = 77;
    }

    if (subNav && windowWidth > 420) {
      headerHeight = headerHeight + subNav.scrollHeight;
    }

    return headerHeight;
  }
}

export default LinkTargets;
