// Smooth Scrolling without jQuery
// Based on: https://coderwall.com/p/hujlhg/smooth-scrolling-without-jquery
import { getScrollTop, setScrollTop, getUserAgent } from "./dom-helpers";

// based on http://en.wikipedia.org/wiki/Smoothstep
const smoothStep = function (start, end, point) {
  if (point <= start) {
    return 0;
  }
  if (point >= end) {
    return 1;
  }
  const x = (point - start) / (end - start);
  return x * x * (3 - 2 * x);
};

const smoothScroll = function (element, offsetValue, callback) {
  const offset = typeof offsetValue !== "undefined" ? offsetValue : 0;
  const targetRect = element.getBoundingClientRect();
  const target = targetRect.top + getScrollTop();

  // Set scroll speed based on where element is
  let duration = target / 2;

  if (duration === 0) {
    // setScrollTop(target);
    duration = 300;
  }

  const startTime = Date.now();
  const endTime = startTime + duration;

  const startTop = getScrollTop();
  const distance = target - startTop - offset;
  // Running value that gets updated to compare frame scrolls
  let previousTop = startTop;

  // Looping frame scroll
  const scrollFrame = function () {
    // If scroll was interrupted, stop that stuff
    if (getScrollTop() !== previousTop) {
      return;
    }

    // Set scrolltop for this frame
    const now = Date.now();
    const point = smoothStep(startTime, endTime, now);
    setScrollTop(Math.round(startTop + distance * point));

    // Check if done
    if (now >= endTime) {
      // Run a callback if there is one
      if (callback) {
        callback();
      }
      return;
    }

    previousTop = getScrollTop();

    // Schedule next frame
    setTimeout(scrollFrame, 0);
  };

  // On Desktop...
  if (getUserAgent() !== "android" && getUserAgent() !== "ios") {
    // Start animation
    setTimeout(scrollFrame, 0);
  } else {
    // On mobile, just go to the point
    setScrollTop(startTop + distance);
  }
};

export default smoothScroll;
