const $ = window.jQuery;

class contentBlocks {
  constructor(
    wrapperClass = "content-wrapper",
    classTheFirst = "white-background",
    classTheSecond = "gray-background",
    resetClass = "hero-slideshow"
  ) {
    // Intialize alternating content blocks: ONLY WORKS IF ALL CONTENT BLOCK SETS ARE SIBLINGS!!
    const firstContentBlock = $("." + wrapperClass).first();
    const groupedSibs = [[firstContentBlock]];
    let adjSibs = [];
    const $sibs = $(firstContentBlock).nextAll();

    $sibs.each(function (i) {
      if ($(this).hasClass(wrapperClass)) {
        if (i === 0) {
          groupedSibs[0].push(this);
        } else {
          adjSibs.push(this);
        }

        if (i === $sibs.length - 1) {
          groupedSibs.push(adjSibs);
        }
      } else if (adjSibs.length > 0 && $(this).hasClass(resetClass)) {
        groupedSibs.push(adjSibs);
        adjSibs = [];
      }
    });

    groupedSibs.forEach(function (adjSibs) {
      adjSibs.forEach(function (sib, i) {
        if (
          !$(sib).hasClass("white-background") &&
          !$(sib).hasClass("gray-background")
        ) {
          if (i % 2 === 0) {
            $(sib).addClass("white-background");
          } else {
            $(sib).addClass("gray-background");
          }
        }
      });
    });
  }
}

export default contentBlocks;
