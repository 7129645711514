import throttle from "lodash/throttle";

export default {
  props: {
    teamMember: Object,
    categories: Array,
    currentCategorySlug: String,
    id: Number,
    activeBio: Number,
  },
  data: function () {
    return {
      teamMemberOpen: false,
      bioHeight: 0,
    };
  },
  computed: {
    teamMemberThumb: function () {
      let output = "";

      if (this.teamMember.image) {
        output += encodeURI(this.teamMember.image);
      } else {
        output =
          "/themes/deacon/assets/images/placeholder/staff_placeholder.png";
      }

      return output;
    },
    teamMemberImage: function () {
      let output = "";

      if (this.teamMember.secondary_image) {
        output += encodeURI(this.teamMember.secondary_image);
      } else {
        output = null;
      }

      return output;
    },
  },
  methods: {
    toggleTeamMember: function () {
      const bio = this.$el.querySelector(".team-member-bio");

      if (this.teamMemberOpen) {
        bio.style.maxHeight = "200vh";
        setTimeout(() => {
          this.bioHeight = bio.offsetHeight;
        }, 150);
      } else {
        bio.style.maxHeight = 0;
        setTimeout(() => {
          this.bioHeight = 0;
        }, 150);
      }
    },
    setBioStatus: function (optionalFlag) {
      if (optionalFlag === "mobile") {
        if (this.teamMemberOpen) {
          this.$emit("bio-status", null);
        } else if (this.teamMember.bio || this.teamMemberImage) {
          this.$emit("bio-status", this.id);
        }
      } else {
        if (this.teamMemberOpen) {
          this.$emit("bio-status", null);
        } else {
          this.$emit("bio-status", this.id);
        }
      }
    },
    unsaltEmail: function (e) {
      let unsaltedHref = "mailto:";

      [...this.teamMember.saltyEmail.split("")].forEach(function (char) {
        unsaltedHref =
          unsaltedHref + String.fromCharCode(char.charCodeAt(0) - 5);
      });

      window.location.href = unsaltedHref;
    },
  },
  watch: {
    activeBio: function () {
      if (this.id === this.activeBio) {
        this.teamMemberOpen = true;
      } else {
        this.teamMemberOpen = false;
      }

      this.toggleTeamMember();
    },
  },
  mounted: function () {
    window.addEventListener(
      "resize",
      throttle(() => {
        if (this.teamMemberOpen) {
          this.bioHeight =
            this.$el.querySelector(".team-member-bio").offsetHeight;
        }
      })
    );
  },
  template: `
  <div>
    <div
      class="team-member-thumb"
      v-on:click="setBioStatus"
      v-bind:class="{ active: teamMemberOpen }"
    >
      <div
        v-bind:style="{ backgroundImage: 'url(' + teamMemberThumb + ')' }"
        class="team-member-portrait"
      ></div>
      <div
        class="team-member-overlay"
        v-bind:class="{ active: teamMemberOpen }"
      >
        <div class="overlay-background"></div>
        <div class="team-member-thumb-info">
          <div class="team-member-name" v-show="!teamMemberOpen">
            {{ teamMember.name }}
          </div>
          <div class="team-member-department" v-show="!teamMemberOpen">
            <a
              class="icon-email"
              v-if="teamMember.saltyEmail"
              v-bind:href="'mailto:' + teamMember.saltyEmail"
              v-on:click.prevent="unsaltEmail"
            ></a>
            <span class="team-member-job">{{ teamMember.job_title }}</span>
            <span
              class="vertical-divider"
              v-if="teamMember.locations.length > 0"
            >
              |
            </span>
            <span
              class="team-member-location"
              v-for="(location, index) in teamMember.locations"
              >{{ location.name
              }}<span v-if="index < teamMember.locations.length - 1"
                >,
              </span></span
            >
          </div>
          <div
            class="team-member-bio-toggle mobile"
            v-bind:class="{ active: teamMemberOpen }"
            v-show="teamMember.bio || teamMemberImage"
          ></div>
          <div
            class="team-member-bio-toggle"
            v-bind:class="{ active: teamMemberOpen }"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="team-member-thumb mobile"
      v-on:click="setBioStatus('mobile')"
      v-bind:class="{ active: teamMemberOpen }"
    >
      <div
        v-bind:style="{ backgroundImage: 'url(' + teamMemberThumb + ')' }"
        class="team-member-portrait"
      ></div>
      <div
        class="team-member-overlay"
        v-bind:class="{ active: teamMemberOpen }"
      >
        <div class="overlay-background"></div>
        <div class="team-member-thumb-info">
          <div class="team-member-name" v-show="!teamMemberOpen">
            {{ teamMember.name }}
          </div>
          <div class="team-member-department" v-show="!teamMemberOpen">
            <a
              class="icon-email"
              v-if="teamMember.saltyEmail"
              v-bind:href="'mailto:' + teamMember.saltyEmail"
              v-on:click.prevent="unsaltEmail"
            ></a>
            <span class="team-member-job">{{ teamMember.job_title }}</span>
            <span
              class="vertical-divider"
              v-if="teamMember.locations.length > 0"
            >
              |
            </span>
            <span
              class="team-member-location"
              v-for="(location, index) in teamMember.locations"
              >{{ location.name
              }}<span v-if="index < teamMember.locations.length - 1"
                >,
              </span></span
            >
          </div>
          <div
            class="team-member-bio-toggle mobile"
            v-bind:class="{ active: teamMemberOpen }"
            v-show="teamMember.bio || teamMemberImage"
          ></div>
          <div
            class="team-member-bio-toggle"
            v-bind:class="{ active: teamMemberOpen }"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="main-container team-member-bio"
      v-bind:class="{ active: teamMemberOpen }"
    >
      <div class="bio-inner section-container">
        <div class="icon-close" v-on:click="setBioStatus"></div>
        <div class="bio-left" v-if="teamMemberImage">
          <img v-bind:src="teamMemberImage" class="team-member-profile-pic" />
        </div>
        <div class="bio-right" v-bind:class="{ nopic: !teamMemberImage }">
          <div class="team-member-name">{{ teamMember.name }}</div>
          <div class="bio-header">
            <div class="team-member-department">
              <a
                class="icon-email"
                v-if="teamMember.saltyEmail"
                v-bind:href="'mailto:' + teamMember.saltyEmail"
                v-on:click.prevent="unsaltEmail"
              ></a>
              <span class="team-member-job">{{ teamMember.job_title }}</span>
              <span
                class="vertical-divider"
                v-if="teamMember.locations.length > 0"
              >
                |
              </span>
              <span
                class="team-member-location"
                v-for="(location, index) in teamMember.locations"
                >{{ location.name
                }}<span v-if="index < teamMember.locations.length - 1"
                  >,
                </span></span
              >
            </div>
            <hr class="horizontal-divider" />
          </div>
          <div class="team-member-main" v-html="teamMember.bio"></div>
        </div>
        <a
          href="#"
          class="icon-close mobile-only"
          v-on:click.prevent="setBioStatus"
          v-if="teamMember.bio"
        ></a>
        <hr class="horizontal-divider" v-if="teamMember.bio" />
      </div>
    </div>
    <div
      class="expansion-booster"
      v-bind:style="{ height: bioHeight + 'px' }"
    ></div>
  </div>
  `,
};
