import Project from "./Project";
import CategoryHero from "./CategoryHero";

export default {
  props: {
    projects: Array,
    categories: Array,
    currentCategorySlug: String,
  },
  computed: {
    category: function () {
      return this.getCategoryBySlug(this.currentCategorySlug);
    },
  },
  methods: {
    getCategoryBySlug: function (slug) {
      return slug
        ? this.categories.find((category) => category.slug === slug)
        : null;
    },
  },
  components: {
    project: Project,
    "category-hero": CategoryHero,
  },
  template: `
  <div>
    <category-hero
      v-bind:category="category"
    ></category-hero>
    <ul class="projects">
      <li class="project" v-for="project in projects">
        <project
          v-bind:project="project"
          v-bind:categories="categories"
          v-bind:current-category-slug="currentCategorySlug"
        ></project>
      </li>
    </ul>
  </div>
  `,
};
