import { toggleClass } from "./dom-helpers";

// For toggling class on trigger and its set of elements
class Togglers {
  constructor(
    dataToggle = "[data-toggler]",
    dataTarget = "[data-toggler]",
    onClass = "open"
  ) {
    const togglers = document.querySelectorAll(dataToggle);
    const allTargets = document.querySelectorAll(dataToggle);
    const toggleAttr = dataToggle.match(/\[(.*)\]/)[1];
    const targetAttr = dataTarget.match(/\[(.*)\]/)[1];

    [...togglers].forEach((toggler) => {
      toggler.addEventListener("click", function (event) {
        event.preventDefault();
        const toggleId = this.getAttribute(toggleAttr);
        const targets = document.querySelectorAll(
          "[" + targetAttr + '="' + toggleId + '"]'
        );
        toggleClass(this, onClass);

        [...targets].forEach((target) => {
          toggleClass(target, onClass);
        });
      });
    });
  }
}

export default Togglers;
