import { toggleClass } from "./dom-helpers";
const $ = window.jQuery;

class ClassBurger {
  constructor(
    label,
    onClass = "open",
    callback = function () {
      return true;
    }
  ) {
    const dataToggles = document.querySelectorAll(
      "[data-hamburger-togglable=" + label + "]"
    );
    const dataTriggers = document.querySelectorAll(
      "[data-hamburger-toggle=" + label + "]"
    );
    const body = document.querySelector("body");

    if (dataToggles.length > 0) {
      // Bind toggle trigger
      this.bindTrigger(dataToggles, dataTriggers, label, onClass, callback);

      // Bind click handler for closing if clicking outside the hamburger menu
      document.addEventListener("click", function onClickHandler(event) {
        // Click status is True if the overlay should close, False if it should remain open
        let clickStatus = true;
        if ($(event.target).is("a")) {
          clickStatus = true;
        } else if (
          $(event.target).data("hamburger-togglable") ||
          $(event.target).data("hamburger-toggle")
        ) {
          clickStatus = false;
        } else {
          $(event.target)
            .parents()
            .each(function (index) {
              if (
                $(this).data("hamburger-togglable") ||
                $(this).data("hamburger-toggle")
              ) {
                clickStatus = false;
              }
            });
        }

        if (clickStatus) {
          $(body).removeClass("overlay-active");

          [...dataToggles].forEach((toggle) => {
            $(toggle).removeClass(onClass);
          });

          [...dataTriggers].forEach((trigger) => {
            $(trigger).removeClass(onClass);
          });
        }
      });
    } else {
      console.error(
        "Togglable element with value " + label + " does not exist."
      );
    }
  }

  bindTrigger(nodes, triggers, label, onClass, callback) {
    if (triggers.length > 0) {
      // Bind click handler
      [...triggers].forEach((trigger) => {
        trigger.addEventListener("click", function () {
          [...triggers].forEach((internalTrigger) => {
            toggleClass(internalTrigger, onClass);
          });

          [...nodes].forEach((toggle) => {
            toggleClass(toggle, onClass);
            callback(toggle);
          });
        });
      });
    } else {
      console.error(
        "Trigger matching togglable element value" + label + "does not exist"
      );
    }
  }
}

export default ClassBurger;
