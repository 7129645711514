// For toggling class on trigger and its set of elements
class TouchZone {
  constructor(touchZone) {
    let touchstartX = 0;
    let touchstartY = 0;
    let touchendX = 0;
    let touchendY = 0;

    touchZone.addEventListener(
      "touchstart",
      function (event) {
        touchstartX = event.changedTouches[0].screenX;
        touchstartY = event.changedTouches[0].screenY;
      },
      false
    );

    touchZone.addEventListener(
      "touchend",
      function (event) {
        touchendX = event.changedTouches[0].screenX;
        touchendY = event.changedTouches[0].screenY;
        const swiped = "swiped: ";
        let direction = "";
        let deltaX = 0;
        let deltaY = 0;

        if (touchendY < touchstartY) {
          deltaY = touchstartY - touchendY;
        }

        if (touchendY > touchstartY) {
          deltaY = touchendY - touchstartY;
        }

        if (touchendX < touchstartX) {
          deltaX = touchstartX - touchendX;
          direction = "right";
        }

        if (touchendX > touchstartX) {
          deltaX = touchendX - touchstartX;
          direction = "left";
        }

        // Only concerned with left and right swipes for the time being
        // if (touchendY < touchstartY) {
        //   direction = 'down';
        //   console.log(swiped + 'down!');
        // }

        // if (touchendY > touchstartY) {
        //   direction = 'up';
        //   console.log(swiped + 'up!');
        // }

        // if (touchendY == touchstartY) {
        //   direction = 'tap';
        //   console.log('tap!');
        // }

        // Don't emit event if the user swiped farther vertically than horizontally
        if (deltaX > deltaY) {
          const touchEvent = new CustomEvent("custom_swipe", {
            detail: direction,
          });
          touchZone.dispatchEvent(touchEvent);
        }
      },
      false
    );
  }
}

export default TouchZone;
