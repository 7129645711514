import TeamMember from "./TeamMember";

export default {
  props: {
    team: Array,
    categories: Array,
    currentCategorySlug: String,
  },
  data: function () {
    return {
      activeBio: null,
    };
  },
  methods: {
    updateBioStatus: function (id) {
      this.activeBio = id;
    },
  },
  components: {
    "team-member": TeamMember,
  },
  template: `
    <div class="team-grid-wrapper">
        <ul class="team-grid">
            <li class="team-member" v-for="teamMember in team">
                <team-member
                    v-bind:team-member="teamMember"
                    v-bind:categories="categories"
                    v-bind:current-category-slug="currentCategorySlug"
                    v-bind:id="teamMember.id"
                    v-on:bio-status="updateBioStatus"
                    v-bind:active-bio="activeBio"
                ></team-member>
            </li>
        </ul>
    </div>
    `,
};
