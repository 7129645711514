/* eslint-disable max-len, no-useless-escape */
const YOUTUBE_REGEX =
  /^https?:\/\/(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?v=)|youtu\.be\/)([^?&\/]*)/i;
const VIMEO_REGEX = /https?:\/\/([a-z]+\.)?vimeo.com\/(video\/)?(\d+)($|\/)/i;
/* eslint-enable max-len, no-useless-escape */

export default {
  props: {
    videoUrl: null,
  },
  computed: {
    video: function () {
      if (!this.videoUrl) return null;

      const youtubeMatch = this.videoUrl.match(YOUTUBE_REGEX);
      const vimeoMatch = this.videoUrl.match(VIMEO_REGEX);

      if (youtubeMatch) {
        return {
          type: "youtube",
          id: youtubeMatch[1],
          url: `https://www.youtube.com/embed/${youtubeMatch[1]}?enablejsapi=1&playlist=${youtubeMatch[1]}&modestbranding=1&showinfo=0&rel=0`,
        };
      } else if (vimeoMatch) {
        return {
          type: "vimeo",
          id: vimeoMatch[3],
          url: `https://player.vimeo.com/video/${vimeoMatch[3]}?title=0&portrait=false`,
        };
      }

      return null;
    },
    isVimeo: function () {
      return this.video && this.video.type === "vimeo";
    },
  },
  mounted() {
    if (this.isVimeo) {
      const vimeoScript = document.createElement("script");
      vimeoScript.setAttribute("src", "https://player.vimeo.com/api/player.js");
      document.head.appendChild(vimeoScript);
    }
  },
  template: `
    <div v-if="video && video.id" class="m-category-video">
      <iframe class="m-category-video__iframe"
        v-bind:id="'player-' + video.id"
        v-bind:src="video.url" 
        frameborder="0"></iframe>
    </div>
  `,
};
