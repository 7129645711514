// Import CIC classes, functions
import Vue from "vue";
import Headroom from "headroom.js";
import {
  ready,
  addEscListener,
  hasClass,
  addClass,
  removeClass,
  setScrollTop,
} from "./dom-helpers";
import Links from "./links";
import LinkTargets from "./link-targets";
import ClassBurger from "./class-burger";
import Togglers from "./togglers";
import CssSlider from "./css-slider";
import TouchZone from "./touch-zone";
import AnchorNavs from "./anchor-navs";
import ContentBlocks from "./content-blocks";
import FilterableList from "./filterable-list";
import fluidvids from "./fluidvids.min.js";
import CheckFlexGap from "./CheckFlexGap";
import setSelectValue from "./set-select-value";

const $ = window.jQuery;

class DeaconTheme {
  init() {
    // When DOM is ready///
    ready(function () {
      if (document.querySelector("#filterable-list")) {
        const vm = new Vue(FilterableList);
      }

      // Initialize sticky headers
      const stickySiteHeaders = document.querySelectorAll(
        "[data-sticky-header=site-header]"
      );
      const stickySubNavs = document.querySelectorAll(
        "[data-sticky-header=sub-nav]"
      );
      let headerHeight = 79;
      let siteHeaderHeight = 132;

      if (window.innerWidth <= 420) {
        headerHeight = 77;
        siteHeaderHeight = 77;
      }

      // Initialize month select
      const elMonthSelect = document.getElementById("monthSelect");

      if (elMonthSelect) {
        setSelectValue(elMonthSelect, "month");
      }

      if (stickySiteHeaders.length > 0) {
        [...stickySiteHeaders].forEach(function (header) {
          // $(header).sticky({responsiveWidth: true, className: 'sticky', wrapperClassName: 'sticky-site-header-wrapper', zIndex: 100});
          const headroomSiteHeader = new Headroom(header, {
            // vertical offset in px before element is first unpinned
            offset: siteHeaderHeight,
            // css classes to apply
            classes: {
              notTop: "sticky",
            },
            onNotTop: function () {
              headroomSiteHeader.offset = 81;
            },
            onTop: function () {
              headroomSiteHeader.offset = siteHeaderHeight;
            },
          });

          headroomSiteHeader.init();
        });
      }

      if (stickySubNavs.length > 0) {
        [...stickySubNavs].forEach(function (subNav) {
          $(subNav).sticky({
            responsiveWidth: true,
            className: "sticky",
            wrapperClassName: "sticky-sub-nav-wrapper",
            zIndex: 100,
            topSpacing: headerHeight,
          });
        });
      }

      // Initialize Overlay Burgers
      const hamburgerOverlay = new ClassBurger("hamburger", "active", function (
        node
      ) {
        addEscListener(node, "active");

        const body = document.querySelector("body");

        if (hasClass(node, "active")) {
          addClass(body, "overlay-active");
        } else {
          removeClass(body, "overlay-active");
        }
      });

      const Panels = new Togglers(
        "[data-panel-toggle]",
        "[data-panel]",
        "open"
      );
      const Toggles = new Togglers(
        "[data-toggler]",
        "[data-togglable]",
        "active"
      );

      // Initialize smooth Scrolling anchor links and external links
      const allLinks = new Links();

      // Initialize smooth Scrolling anchor link targets
      const allLinkTargets = new LinkTargets();

      // Initalize Anchor Link Navs
      if (document.querySelectorAll("[data-anchor-nav-item]").length > 0) {
        const anchorNavs = new AnchorNavs();
      }

      // Scroll to link if there's a # in the url
      const urlHash = document.location.hash;
      if (urlHash.length > 0) {
        // Find match
        const hashChunks = urlHash.split("#");
        const match = document.querySelector(
          'a[name="' + hashChunks[hashChunks.length - 1] + '"]'
        );

        // Bind smooth scrolling and reset the hash when it gets there
        if (match) {
          setScrollTop($(match).offset().top);
        }
      }

      // Intialize alternating content blocks: ONLY WORKS IF ALL CONTENT BLOCK SETS ARE SIBLINGS!!
      const contentBlocks = new ContentBlocks();

      // Instantiate slider JS for each slider on a page
      const sliders = document.querySelectorAll("[data-slider]");
      [...sliders].forEach(function (slider) {
        const cssSlider = new CssSlider(
          slider.querySelector("[data-element]").getAttribute("data-count"),
          slider
        );
        slider.style.opacity = "1";
      });

      // Instantiate touch zones
      const touchzones = document.querySelectorAll("[data-touch-zone]");
      [...touchzones].forEach(function (touchZone) {
        const newTouchZone = new TouchZone(touchZone);
      });

      // Instantiate responsive video embeds
      if (document.querySelectorAll(".fluid-vid").length > 0) {
        const firstScriptTag = document.getElementsByTagName("script")[0];
        const youtubeTag = document.createElement("script");
        // var vimeoTag = document.createElement('script');
        youtubeTag.src = "https://www.youtube.com/iframe_api";
        // vimeoTag.src = "https://player.vimeo.com/api/player.js";
        firstScriptTag.parentNode.insertBefore(youtubeTag, firstScriptTag);
        // firstScriptTag.parentNode.insertBefore(vimeoTag, firstScriptTag);

        fluidvids.init({
          selector: [".fluid-vid"],
          players: ["www.youtube.com", "player.vimeo.com"],
        });
      }

      // Check if flex gap is supported
      const flexGapCheck = new CheckFlexGap();
    });
  }
}

export default DeaconTheme;
