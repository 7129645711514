import throttle from "lodash/throttle";

const $ = window.jQuery;
const getScrollTop = require("./dom-helpers.js").getScrollTop;

class AnchorNavs {
  constructor(selector = "[data-anchor-nav-item]", onClass = "active") {
    const offset = 100;
    const attr = selector.match(/\[(.*)\]/)[1];
    this.posCheck(selector, attr, offset, onClass);

    window.addEventListener(
      "scroll",
      throttle(() => {
        this.posCheck(selector, attr, offset, onClass);
      }, 300)
    );

    window.addEventListener(
      "resize",
      throttle(() => {
        this.posCheck(selector, attr, offset, onClass);
      }, 300)
    );
  }

  posCheck(selector, attr, offset, onClass) {
    const headerHeight =
      document.querySelector("[data-sticky-header=site-header]").scrollHeight +
      document.querySelector("[data-sticky-header=sub-nav]").scrollHeight;
    let activeNavItem;
    let activeAnchorPosY;

    [...document.querySelectorAll(selector)].forEach(
      (navItem, index, navItems) => {
        const match = document.querySelector(
          'a[name="' + $(navItem).data(attr.replace("data-", "")) + '"]'
        );

        if (match) {
          $(navItem).removeClass(onClass);
          const nodePosY = match.getBoundingClientRect().top;
          if (index === 0) {
            activeNavItem = navItem;
            activeAnchorPosY = match.getBoundingClientRect().top;
          } else if (
            nodePosY < headerHeight + offset &&
            nodePosY > activeAnchorPosY
          ) {
            activeNavItem = navItem;
            activeAnchorPosY = nodePosY;
          }
        }
      }
    );

    $(activeNavItem).addClass(onClass);
  }
}

export default AnchorNavs;
