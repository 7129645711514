export default function setSelectValue(elSelect, queryParam) {
  if (!elSelect) return;

  let value = "";

  if (URLSearchParams && window) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    value = params ? params[queryParam] : value;
  }

  for (let i, j = 0; (i = elSelect.options[j]); j++) {
    if (i.value === value) {
      elSelect.selectedIndex = j;
      break;
    }
  }
}
