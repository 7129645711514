export default {
  props: {
    project: Object,
    categories: Array,
    currentCategorySlug: String,
  },
  computed: {
    projectLink: function () {
      // Either output the default project link, or one with a category appended
      let output = this.project.detailUri;
      if (this.currentCategorySlug) {
        output = this.project.detailWithCategoryUri + this.currentCategorySlug;
      }
      return output;
    },
    projectImage: function () {
      let output = "";
      if (this.project.image) {
        output += encodeURI(this.project.image);
      } else {
        if (this.project.hero_image.isArray) {
          output += encodeURI(this.project.hero_image["1"].image);
        }
      }
      return output;
    },
    categoryList: function () {
      // Returns a string with a list of project names
      // Set an empty string
      let output = "";

      // If the project has category Ids
      if (this.project.categoryIds.length > 0) {
        // Setup a blank array
        const categoryMatches = [];
        const categories = this.categories;
        // Find categories that match the ID, and addit to the array
        this.project.categoryIds.forEach(function (id) {
          categoryMatches.push(
            categories.filter(function (category) {
              return id === category.id;
            })
          );
        });

        // Slot the names onto the string (in reverse)
        categoryMatches.forEach(function (match) {
          if (output !== "") {
            output += " / ";
          }

          output += match[0].name;
        });
      }

      return output;
    },
  },
  template: `
    <a v-bind:href="projectLink" class="project-thumb">
    <div v-bind:style="{backgroundImage: 'url(' + projectImage + ')'}" class="project-pic"></div>
    <div class="project-overlay">
      <div class="overlay-background"></div>
      <div class="project-thumb-info">
        <div class="project-name">{{ project.name }}</div>
        <div class="project-criteria">
          {{ categoryList }}
        </div>
        <div class="icon-circle-caret-right"></div>
      </div>
    </div>
  </a>`,
};
